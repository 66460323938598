import React from 'react'

function AboutSection() {
  return (
    <section className="pb-7" id="team">
      <div className="container">
        <hr className="my-6" />
        <div className="row">
          <div className="col-md-8 mx-auto text-center">
            <h2 className="section-heading">Our Team</h2>
            <p className="section-subheading text-muted">
              Meet the talented individuals behind our success.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/Anshu.jpg")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 1"
              />
              <h4>Anshu Abhishek</h4>
              <p className="text-muted">Developer</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/anshu-abhishek-3b8195200/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/anshika.jpg")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 2"
              />
              <h4>Anshika</h4>
              <p className="text-muted">Developer</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/anshika-9853b3200/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/harsh.png")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 3"
              />
              <h4>Harsh Chittora</h4>
              <p className="text-muted">Developer</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/harsh-chittora/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/Ansh.jpg")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 3"
              />
              <h4>Ansh Magotra</h4>
              <p className="text-muted">Research Analyst</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/anshmagotra/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/Ayush.jpg")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 3"
              />
              <h4>Ayush Kumar Rai</h4>
              <p className="text-muted">Business Analyst</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/ayush-kumar-rai-4aa500190/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/shivam.jpg")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 3"
              />
              <h4>Shivam Jha</h4>
              <p className="text-muted">SEO Specialist</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/harsh-chittora/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="team-member">
              <img
                src={require("../assets/gurpreet.jpg")}
                style={{ width: "50%" }}
                className="img-fluid rounded-circle member-img"
                alt="Team Member 3"
              />
              <h4>Gurpreet Singh</h4>
              <p className="text-muted">UI/UX Developer <br/> Video Editor</p>
              {/* <ul className="list-inline social social-sm">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/gurpreet-singh-1237901b1/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection