import React from "react";

function PricingSection() {
  return (
    <section
      className="py-7 bg-dark section-angle top-right bottom-right"
      id="pricing"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <h2 className="text-white heading-black">Choose your plan.</h2>
            <p className="text-light lead">For pricing contact</p>
          </div>
        </div>
        {/* pricing tables */}
        <div className="row pt-5 pricing-table">
          <div className="col-12 mx-auto">
            <div className="card-deck pricing-table d-flex flex-column flex-md-row">
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <h3 className="card-title pt-3">Websites</h3>
                  {/* <div className="text-muted font-weight-medium mt-2">
                    One time
                  </div> */}
                  <ul className="list-unstyled pricing-list">
                    <li>Customized Design</li>
                    <li>Dynamic Updates</li>
                    <li>Payment Gateway</li>
                    <li>Engaging UX/UI</li>
                    <li>Secure Hosting</li>
                    <li>Responsive Layouts</li>
                    <li>SEO Friendly</li>
                  </ul>
                  <address>
                    <a
                      href="mailto:anshusinha872@gmail.com"
                      className="btn btn-primary"
                    >
                      Contact
                    </a>
                  </address>
                </div>
              </div>
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <h3 className="card-title pt-3">Applications</h3>
                  {/* <div className="text-muted font-weight-medium mt-2">
                    One Time
                  </div> */}
                  <ul className="list-unstyled pricing-list">
                    <li>Scalable Solutions</li>
                    <li>Intuitive Interfaces</li>
                    <li>Reliable Maintenance</li>
                    <li>Advanced Functionality</li>
                    <li>Agile Development</li>
                    <li>Cloud Integration</li>
                    <li>Data-driven Insights</li>
                  </ul>
                  <address>
                    <a
                      href="mailto:anshusinha872@gmail.com"
                      className="btn btn-primary"
                    >
                      Contact
                    </a>
                  </address>
                </div>
              </div>
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <h3 className="card-title pt-3">Shopify and Wordpress</h3>
                  {/* <div className="text-muted font-weight-medium mt-2">
                    Platform based
                  </div> */}
                  <ul className="list-unstyled pricing-list">
                    <li>Tailored Platforms</li>
                    <li>Seamless Integration</li>
                    <li>Conversion Optimization</li>
                    <li>Mobile Responsiveness</li>
                    <li>SEO Enhancement</li>
                    <li>Payment Gateway</li>
                    <li></li>
                  </ul>
                  <address>
                    <a
                      href="mailto:anshusinha872@gmail.com"
                      className="btn btn-primary"
                    >
                      Contact
                    </a>
                  </address>
                </div>
              </div>
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <h3 className="card-title pt-3">Service and Maintenance</h3>
                  {/* <div className="text-muted font-weight-medium mt-2">
                    Accoring to your project
                  </div> */}
                  <ul className="list-unstyled pricing-list">
                    <li>Regular Updates</li>
                    <li>Performance</li>
                    <li>24/7 Support</li>
                    <li>Security Audits</li>
                    <li>Data Backup</li>
                    <li>Bug Fixing</li>
                  </ul>
                  <address>
                    <a
                      href="mailto:anshusinha872@gmail.com"
                      className="btn btn-primary"
                    >
                      Contact
                    </a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-6">
          <div className="col-md-4">
            <h3>Everything is covered.</h3>
            <p className="lead">
              Embark on a journey of comprehensive support with Instincts,
              offering timely updates, round-the-clock assistance, transparent
              pricing, SEO guidance, and unlimited support.
            </p>
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <div className="col-md-12">
              <ul className="features-list">
                <li>Timely update</li>
                <li>Always remains in touch</li>
                <li>24/7 assistance</li>
                <li>No Hidden charges</li>
                <li>Help in SEO also</li>
                <li>Free unlimited support</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-8 col-12 divider top-divider mx-auto pt-5 text-center">
            <h3>Try Instincts</h3>
            <p className="mb-4">
              Experience the transformative power of Instincts: Your digital
              ally crafting tailored solutions for elevated online presence and
              seamless user experiences
            </p>
            <address>
              <a
                href="mailto:anshusinha872@gmail.com"
                className="btn btn-primary"
              >
                Contact
              </a>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;
