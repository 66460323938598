import React from "react";

function ProjectSection() {
  return (
    <section
      className="py-7 bg-dark section-angle top-left bottom-left"
      id="blog"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <h2 className="heading-black">Projects</h2>
            <p className="text-muted lead">What's Projects at Instincts.</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a href="https://examsaathi.com/">
                <img
                  className="card-img-top img-raised"
                  src={require("../assets/examsaathi.png")}
                  alt="Blog 1"
                  height="300"
                />
              </a>
              <div className="card-body">
                <a href="https://examsaathi.com/" className="card-title mb-2">
                  <h5>Created Examsaathi Website</h5>
                </a>
                <p className="text-muted small-xl mb-2">December, 2023</p>
                <p className="card-text">
                  Your ultimate destination for comprehensive exam preparation
                  and testing. Empowering learners with a seamless online
                  platform for success.
                </p>
                <a
                  href="https://examsaathi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a href="#">
                <img
                  className="card-img-top img-raised"
                  src={require("../assets/invoicecraft.png")}
                  alt="Blog 2"
                  height="300"
                />
              </a>
              <div className="card-body">
                <a href="#" className="card-title mb-2">
                  <h5>One stop solution for Invoice</h5>
                </a>
                <p className="text-muted small-xl mb-2">October, 2023</p>
                <p className="card-text">
                  Invoice-craft includes a comprehensive range of features
                  designed to make invoicing, billing, and payment management
                  easier and more efficient.
                </p>
                <a
                  href="https://invoicecraft.co.in/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div> */}
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a href="#">
                <img
                  className="card-img-top img-raised"
                  src={require("../assets/invoicecraftApp.png")}
                  alt="Blog 3"
                  height="300"
                />
              </a>
              <div className="card-body">
                <a href="https://play.google.com/store/apps/details?id=com.anshika103.invoicecraft" className="card-title mb-2">
                  <h5>App for your Invoice Solution</h5>
                </a>
                <p className="text-muted small-xl mb-2">February, 2024</p>
                <p className="card-text">
                  Simplify payment management with Invoice-craft App, offering
                  automated calculations and real-time tracking for maximum
                  efficiency and convenience.
                </p>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSection;